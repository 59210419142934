import axios from "axios";
import { action, computed, makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import loadingStore from "../LoadingStore/LoadingStore";

// Define the Notification type
type Notification = {
  enc_id: string;
  content: string;
  created_at: string;
  is_read: number;
  url?: string;
};

export default class NotificationStore {
  notifications: Notification[] = [];
  fetched = false; // Added to prevent multiple API calls

  constructor() {
    makeAutoObservable(this, {
      fetchAllNotifications: action,
      fetchUserNotifications: action,
      changeNotificationStatus: action,
      markAllAsRead: action,
      unreadCount: computed,
    });
  }

  public fetchAllNotifications = async (payload: { enc_id?: string }): Promise<any> => {
    if (this.fetched) return; // Prevent re-fetching if already fetched
    try {
      loadingStore.setAppLoading(true);
      const { data } = await axios.post(API_URL.NOTIFICATIONS.NOTIFICATIONS_LIST, payload);
      // this.notifications = data.data;
      this.notifications = data?.data || []; // Ensure data assignment

      this.fetched = true; // Mark as fetched
      return Promise.resolve(data);
    } catch (err: any) {
      console.error("fetchAllNotifications Error:", JSON.stringify(err.response?.data || err.message));
      return Promise.reject(JSON.stringify(err.response?.data || err.message));
    } finally {
      loadingStore.setAppLoading(false);
    }
  };

  public fetchUserNotifications = async (payload: { enc_id?: string }): Promise<any> => {
    if (this.fetched) return; // Prevent re-fetching if already fetched
    try {
      loadingStore.setAppLoading(true);
      const { data } = await axios.post(API_URL.NOTIFICATIONS.USER_NOTIFICATION, payload);
      this.notifications = data?.data || []; // Ensure data assignment
      this.fetched = true; // Mark as fetched
      return Promise.resolve(data);
    } catch (err: any) {
      console.error("fetchUserNotifications Error:", JSON.stringify(err.response?.data || err.message));
      return Promise.reject(JSON.stringify(err.response?.data || err.message));
    } finally {
      loadingStore.setAppLoading(false);
    }
  };

  get unreadCount() {
    return Array.isArray(this.notifications)
      ? this.notifications.filter((notification) => notification.is_read === 0).length
      : 0; // Fallback if notifications is not an array
  }
  

  public changeNotificationStatus = async (payload: { enc_id: any; status: any }): Promise<any> => {
    try {
      loadingStore.setAppLoading(true);
      const url = API_URL.NOTIFICATIONS.NOTIFICATION_STATUS_UPDATE(payload.enc_id);
      const { data } = await axios.post(url, { status: payload.status });
      this.notifications = this.notifications.map((n) =>
        n.enc_id === payload.enc_id ? { ...n, is_read: 1 } : n
      );
      return Promise.resolve(data);
    } catch (err: any) {
      console.error("changeNotificationStatus Error:", JSON.stringify(err.response?.data || err.message));
      return Promise.reject(JSON.stringify(err.response?.data || err.message));
    } finally {
      loadingStore.setAppLoading(false);
    }
  };

  public markAllAsRead = async (payload: { enc_id: any; mark_all_read: any }): Promise<any> => {
    try {
      loadingStore.setAppLoading(true);
      const { data } = await axios.post(API_URL.NOTIFICATIONS.MARK_ALL_AS_READ_NOTIFICATION, payload);
      this.notifications = this.notifications.map((n) => ({ ...n, is_read: 1 }));
      return Promise.resolve(data);
    } catch (err: any) {
      return Promise.reject(JSON.stringify(err.response?.data || err.message));
    } finally {
      loadingStore.setAppLoading(false);
    }
  };
}
