import React, { useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { observer } from "mobx-react";
import useStore from "../../../../store";
import LinkIcon from "@mui/icons-material/Link";

const NotificationList = observer(() => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = React.useState(null);
  const [isMarkAll, setIsMarkAll] = React.useState(false);

  const { NOTIFICATION, AUTH } = useStore();
  const {
    fetchAllNotifications,
    fetchUserNotifications,
    changeNotificationStatus,
    markAllAsRead,
    notifications,
  } = NOTIFICATION;

  const fetchData = React.useRef(false);
  const { user } = AUTH;
  useEffect(() => {
    if (!fetchData.current) {
      fetchData.current = true;
      (async () => {
        try {

          const myProfile =  user;
          if (myProfile?.role_name === "Admin") {
            await fetchAllNotifications({ enc_id: myProfile?.enc_id });
          } else {
            await fetchUserNotifications({ enc_id: myProfile?.enc_id });
          }
        } catch (error) {
          console.error("Error fetching notifications:", error);
        }
      })();
    }
  }, []);

  const handleMarkAsRead = (notificationId: any) => {
    setSelectedNotificationId(notificationId);
    setIsMarkAll(false);
    setOpenDialog(true);
  };

  const handleMarkAllAsRead = () => {
    setIsMarkAll(true);
    setOpenDialog(true);
  };

  const confirmMarkAsRead = async () => {
    try {
      if (isMarkAll) {
        await markAllAsRead({ enc_id: null, mark_all_read: "Yes" });
      } else {
        await changeNotificationStatus({ enc_id: selectedNotificationId, status: 1 });
      }
      setOpenDialog(false);
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  const columnDefs = [
    { headerName: "S.No", valueGetter: "node.rowIndex + 1", width: 100 },
    { headerName: "Notification", field: "content", flex: 1 },
    { headerName: "Date", field: "created_at", width: 150 },
    {
      headerName: "Link",
      field: "url",
      width: 150,
      cellRenderer: (params: any) =>
        params.value && (
          <IconButton
          color="primary"
          component="a"
          href={params.data.url}
          target="_blank"
          aria-label="open-link"
          sx={{
            color: '#36a3f7', // Default color
            borderColor: '#36a3f7', // Border color for default state
            '&:hover': {
              color: '#fff', // Text color on hover
              backgroundColor: '#36a3f7', // Fill color on hover
              borderColor: '#36a3f7', // Border color on hover
            },
            border: '1px solid transparent', // Default border
            padding: '2px',
          }}
        >
          <LinkIcon />
        </IconButton>
        ),
    },
    {
      headerName: "Action",
      field: "action",
      width: 150,
      cellRenderer: (params: any) =>
        params.data.is_read === 0 ? (
          <Button
          color="primary"
          variant="outlined"
          onClick={() => handleMarkAsRead(params.data.enc_id)}
          sx={{
            height: '25px',
            fontSize: '0.875rem',
            padding: '0 10px',
            minWidth: '100px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            textAlign: 'center',
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'white',
            },
          }}
        >
          Mark Read
        </Button>
        ) : (
          ""
        ),
    },
  ];

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: '20px' }}>
        <Typography variant="h4">Notifications</Typography>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleMarkAllAsRead} // Click to mark all as read
          sx={{
            height: '35px',
            fontSize: '0.875rem',
            padding: '0 10px',
            minWidth: '100px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            textAlign: 'center',
            backgroundColor: 'primary.main',
            color: 'white',
            borderColor: 'primary.main',
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: 'primary.main',
              color: 'primary.main',
            },
          }}
        >
          Mark all as Read
        </Button>
      </Stack>
      <div className="ag-theme-alpine" style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={Array.isArray(notifications) ? notifications : []} // Ensure it's an array
          columnDefs={columnDefs}
          domLayout="autoHeight"
          pagination={true}
          paginationPageSize={10}
          suppressCellFocus={true}
        />
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          Are you sure you want to mark {isMarkAll ? "all notifications" : "this notification"} as read?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={confirmMarkAsRead}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default NotificationList;
