import React, { useState, useEffect, useRef } from 'react';
import {
  Menu,
  MenuItem,
  Box,
  Typography,
  Button,
  Divider,
  Badge,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { format, isToday, isYesterday } from 'date-fns';
import noNotificationsImage from '../../assets/images/noNotification.png';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import useStore from '../../store';

interface Notification {
  is_read: any;
  enc_id: number;
  content: string;
  created_at: Date;
  url: string;
}

interface NotificationMenuProps {
  user: any; // Replace `any` with the appropriate type for your `user` object
}

const NotificationMenu: React.FC<NotificationMenuProps> = ({ user }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(false);
  const [anchorElNotif, setAnchorElNotif] = useState<null | HTMLElement>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const navigate = useNavigate();
  const { AUTH, NOTIFICATION } = useStore();
  const { fetchAllNotifications, fetchUserNotifications, markAllAsRead, unreadCount } = NOTIFICATION;
  const isFetching = useRef(false);

  const { enc_id, role_name } = user;

  useEffect(() => {
    if (!isFetching.current) {
      fetchNotifications();
    }
  }, []);

  const fetchNotifications = async () => {
    try {
      if (isFetching.current) return;
      isFetching.current = true;
      setLoading(true);
      const response =
        role_name === 'Admin'
          ? await fetchAllNotifications({ enc_id })
          : await fetchUserNotifications({ enc_id });
      if (response?.data) {
        setNotifications(response.data || []);
      } else {
        console.error('Unexpected API response format:', response);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      isFetching.current = false;
      setLoading(false);
    }
  };

  const handleOpenNotifMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotif(event.currentTarget);
  };

  const handleCloseNotifMenu = () => {
    setAnchorElNotif(null);
  };

  const handleViewAll = () => {
    navigate('/notifications/all');
    handleCloseNotifMenu();
  };

  const handleMarkAllAsRead = async () => {
    try {
      const response = await markAllAsRead({ enc_id, mark_all_read: 'Yes' });
      if (response?.error === false) {
        setNotifications((prevNotifications) =>
          prevNotifications.map((notif) => ({ ...notif, is_read: true }))
        );
        setOpenConfirmDialog(false);
      } else {
        console.error('Failed to mark notifications as read:', response);
      }
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  const getDateLabel = (date: Date) => {
    if (isToday(date)) return 'Today';
    if (isYesterday(date)) return 'Yesterday';
    return format(date, 'MMMM dd, yyyy');
  };

  const groupedNotifications = notifications
    .filter((notification) => notification.is_read === 0)
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    .reduce((groups, notification) => {
      const dateLabel = getDateLabel(new Date(notification.created_at));
      if (!groups[dateLabel]) groups[dateLabel] = [];
      groups[dateLabel].push(notification);
      return groups;
    }, {} as Record<string, Notification[]>);

  const hasUnreadNotifications = Object.keys(groupedNotifications).length > 0;

  return (
    <div>
      <Tooltip title="Notifications">
        <IconButton color="inherit" onClick={handleOpenNotifMenu} sx={{ ml: 2 }}>
          <Badge badgeContent={unreadCount} color="error">
            <NotificationImportantIcon />
          </Badge>
        </IconButton>
      </Tooltip>

        <Menu
        anchorEl={anchorElNotif}
        open={Boolean(anchorElNotif)}
        onClose={handleCloseNotifMenu}
        sx={{ mt: '45px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
              Notifications
            </Typography>
            {unreadCount > 0 && (
              <Button
                color="secondary"
                onClick={() => setOpenConfirmDialog(true)}
                sx={{ fontSize: '0.75rem', textTransform: 'none' }}
              >
                Mark All as Read
              </Button>
            )}
          </Box>
        </MenuItem>
        <Divider />

        {/* Conditional rendering based on unreadCount */}
        {loading ? (
          <Box sx={{ padding: '32px', textAlign: 'center' }}>Loading...</Box>
        ) : unreadCount === 0 ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '32px',
              height: '500px',
              width: '100%',
            }}
          >
            <img
              src={noNotificationsImage}
              alt="No Notifications Found"
              style={{ maxWidth: '80%', height: 'auto' }}
            />
            <Typography
              variant="body1"
              sx={{
                marginTop: '16px',
                color: 'gray',
                fontSize: '1.25rem',
                fontWeight: 'bold',
              }}
            >
              No Unread Notifications
            </Typography>
          </Box>
        ) : (
          Object.keys(groupedNotifications).map((dateLabel) => (
            <Box key={dateLabel}>
              <Typography variant="subtitle2" sx={{ padding: '8px 16px', fontWeight: 'bold' }}>
                {dateLabel}
              </Typography>
              <Divider />
              {groupedNotifications[dateLabel].map((notification) => (
                <MenuItem
                  key={notification.enc_id}
                  onClick={() => window.open(notification.url, '_blank')}
                >
                  <Typography>{notification.content}</Typography>
                </MenuItem>
              ))}
              <Divider />
            </Box>
          ))
        )}

        {unreadCount > 0 && (
          <Button color="secondary" fullWidth onClick={handleViewAll}>
            View All
          </Button>
        )}
      </Menu>


      <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
        <DialogTitle>Confirm Mark All as Read</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to mark all notifications as read?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleMarkAllAsRead} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NotificationMenu;
