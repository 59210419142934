import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom'
import {
  Card,
  Box,
  CardContent,
  Typography,
  TextField,
  MenuItem,
  CardActions,
  Button,
  Grid,
} from "@mui/material";
import * as uuid from "uuid";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import useStore from "../../../../store";
import { yupResolver } from "@hookform/resolvers/yup";
import { BaseResponse } from "../../../../interfaces/ApiResponse";
import { PermissionRenderTree } from "../../../../store/RoleStore/PermissionInterface";
import PermissionTreeView from "./PermissionTreeView";
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'
import AlertComponent, { AlertData } from "../../../../components/utilities/AlertComponent";

const validationSchema = Yup.object().shape({
  name: Yup.string()
  .required("Role name is required")
  .min(2, "Role must be at least 2 characters long")
  .matches(/^[a-zA-Z0-9 .]+$/, "Only letters, numbers, spaces, and periods are allowed"),
  status: Yup.string().required("Status is required"),
});

const RoleEdit = () => {
  const navigate = useNavigate()
  const uuidValue = () => uuid.v4();
  const { id } = useParams();
  const { ROLE } = useStore();
  const { updateRole, fetchRole } = ROLE;
  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<PermissionRenderTree[]>([]);

  const {
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    defaultValues: {
      name: "",
      status: "1",
    },
  });

  // if all values in array X are present in array Y
  const areAllValuesPresent = (arrayX: string[], arrayY: string[]) => {
    return arrayX.every(valueX => arrayY.indexOf(valueX) !== -1);
  }

  const setInitialFormValues = async (_formData:any) => {
    await setValue('name', _formData?.name || "");
    await setValue('status', _formData?.status || 0);
    return true
  };

  const fetchRoleData = async (_id:string|undefined) => {
    try {
      const resData: BaseResponse = await fetchRole(_id);
      if(resData.error === false){
          await setInitialFormValues(resData?.data?.role);
          const rolePermisionsIds = resData.data.role.permissions.map((e:any) => String(e.id));
          await setSelectedIds(rolePermisionsIds);
          const updatePermissionTreeData = resData?.data?.role?.permissionsTreeViewData.map((permission:any) => {
            const childIds: string[] = []
            permission.children.map((e:any) => {
               if(rolePermisionsIds.includes(e.id)){
                 e.checked = true;
                }
                childIds.push(e.id)
               return e;
             })
             permission.checked = areAllValuesPresent(childIds, rolePermisionsIds);
             return permission;
           });
          await setPermissions(updatePermissionTreeData);
        }else{
          window.scrollTo({top: 0, behavior: 'smooth' });
          setAlertMessage({severity:'error', message: resData.message, data: resData.data});
          toast.error(resData?.message, Toaster);
        }
      } catch(err:any){
        console.error(err)
        const errData: BaseResponse = err;
        toast.error(errData?.message, Toaster);
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: errData.message, data: errData.data});
      }

  }

  useEffect(()=>{
    fetchRoleData(id);
  }, [id]);

  const onSubmit = async (_data: any) => {
    _data.permissions = selectedIds;
    try {
      const resData: BaseResponse = await updateRole(id, _data);
        if(resData.error === false){
         toast.success(resData?.message, Toaster)
         navigate("../")
        }else{
          window.scrollTo({top: 0, behavior: 'smooth' });
          setAlertMessage({severity:'error', message: resData.message, data: resData.data});
         toast.error(resData?.message, Toaster)
        }
      } catch(err:any){
        console.error(err)
        const errData: BaseResponse = err;
        if(errData.data['name']){
          setError("name", {
            type: "manual",
            message: errData.data['name'][0],
          });
        }
        toast.error(errData?.message, Toaster)
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: errData.message, data: errData.data});
      }
  };

  return (
    <>
      <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
      <Card sx={{ maxWidth: "100%" }} variant="outlined">
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ mt: 2, mb: 2 }}
            >
              Edit Role
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={6}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="name"
                      label="Role Name"
                      variant="outlined"
                      helperText={errors.name?.message}
                      error={errors.name ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="status"
                  control={control}
                  defaultValue={"1"} // Set default value for this field
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-select-currency"
                      select
                      label="Status"
                      fullWidth
                      helperText={errors.status?.message}
                      error={errors.status ? true : false}
                    >
                      <MenuItem key={uuidValue()} value={"1"}>
                        Active
                      </MenuItem>
                      <MenuItem key={uuidValue()} value={"0"}>
                        Blocked
                      </MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
              <PermissionTreeView selected={selectedIds} setSelected={setSelectedIds} permissions={permissions} setPermissions={setPermissions}/>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions  sx={{ mb: 2 , ml: 2}}>
            <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="medium"
              onClick={() => navigate('../')}
            >
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  );
};

export default RoleEdit;
