import React from 'react'
import {
  TextField,
  Grid,
  Autocomplete,
  Tooltip,
  IconButton,
} from '@mui/material'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import { Controller } from 'react-hook-form'
import { observer } from 'mobx-react'

const UserPointFields = observer(
  ({
    id,
    control,
    removeUserFields,
    dropdownUsers,
  }: any) => {
    console.log(id, "id")
    console.log(dropdownUsers, "dropdownUsers")

    return (
      <div key={id}>
        <Grid
          key={id}
        >
        <Grid item xs={12}>
          <Controller
              name="users"
              control={control}
              defaultValue={[]} // Start with an empty array
              render={({ field, fieldState }) => (
                <Autocomplete
                  multiple
                  options={dropdownUsers ?? []} // Valid options only
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  getOptionLabel={(option) => option?.label || ''} // Prevent blank labels
                  value={(field.value || []).filter((user:any) => user.label && user.id)} // Remove blank entries
                  onChange={(e, newValue) => {
                    const filteredValues = newValue.filter(
                      (user) => user.label && user.id // Allow only valid users
                    );
                    field.onChange(filteredValues); // Update field value
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Users"
                      variant="outlined"
                      error={!!fieldState.error}
                      helperText={fieldState?.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={2}>
            <Controller
              name={`users_points.${id}.points`}
              control={control}
              render={({ field, fieldState }) => (
                <div className="d-flex flex-row">
                  <TextField
                    fullWidth
                    id={`users_points.${id}.point`}
                    type="text"
                    label="Points"
                    variant="outlined"
                    helperText={fieldState?.error?.message}
                    error={fieldState.invalid ? true : false}
                    {...field}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip
              title="Remove"
              sx={{ visibility: id === 0 ? 'hidden' : '' }}
            >
              <IconButton onClick={() => removeUserFields(id)}>
                <DeleteForeverRoundedIcon color="error" />
              </IconButton>
            </Tooltip>
          </Grid> */}
        </Grid>
      </div>
    )
  },
)

export default UserPointFields
