import React from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useStore from '../../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../../config/Toaster'

const CancelOrderModal: React.FC<any> = observer(
  ({ visible, close, onStatusChange }) => {
    const navigate = useNavigate()
    const { ORDER } = useStore()
    const { changeStatus, statusValues , remarks, setRemarks, notifyStatusChange } = ORDER

    const handleRemarksChange = (event: any) => {
        setRemarks(event.target.value);
    };

    const changeStatusHandle = async () => {
      try {
        if (!remarks?.trim()) {
          toast.error("Please enter remarks!");
          return;
        }
  
        const updatedValues = { ...statusValues, remarks };
        const resData: any = await changeStatus(updatedValues);
  
        if (!resData.error) {
          toast.success(resData.message);
          setRemarks("");
          close();
          notifyStatusChange()
          // Notify the parent about the change
          if (onStatusChange) onStatusChange();
        } else {
          toast.error(resData.message);
        }
      } catch (err: any) {
        console.error(err);
        toast.error(err.message);
        close();
      }
    }

    return (
      <>
        <div>
          <Dialog
            open={visible}
            onClose={close}
            maxWidth={`sm`}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Confirm Cancellation'}
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="remarks"
                label="Remarks"
                type="text"
                onChange={handleRemarksChange}
                fullWidth
                variant="standard"
                placeholder='Please enter your remarks'
                multiline
                rows={2}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={close}>Close</Button>
              <Button onClick={changeStatusHandle} autoFocus>
                Yes, Cancel!
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    )
  },
)

export default CancelOrderModal
